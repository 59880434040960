
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Kutubee",
  components: {},

  setup() {
    const loading = ref(false);
    const route = useRoute();

    const { t, locale } = useI18n();

    // Set the i18n locale to French
    locale.value = "fr";
    const paymentRef = ref("null");
    onMounted(() => {
      setCurrentPageTitle(t("onlinePayment.failure"));
      paymentRef.value = String(route.query.payment_ref);

      const dbName = String(route.params.dbName);
      ApiService.setHeaderBuilding(String(dbName));
    });
    const startPayment = () => {
      loading.value = true;
      ApiService.get("/konnect/retryPayment/" + paymentRef.value)
        .then(({ data }) => {
          window.open(data.payUrl, "_blank");
        })
        .catch((e) => console.log(e))
        .finally(() => (loading.value = false));
    };
    return { startPayment, loading };
  },
});
